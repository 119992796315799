'use strict';

/**
 * A helper for settings
 *
 * @memberof HashBrown.Common.Service
 */
class SettingsService {
}

module.exports = SettingsService;
